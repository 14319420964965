export const isNullOrEmptyString = (string: string | null | undefined) =>
  string === null || typeof string == 'undefined' || !string?.length;

export const toCapital = string =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const toLowerFirst = string =>
  string.charAt(0).toLowerCase() + string.slice(1);

export const findOrFirstOrNull = (
  array: any,
  value: any,
  key: string = 'value',
) => array.find(x => x[key] === value) || (array.length ? array[0] : {});

export const convertObj = (obj: object, format: object): object[] => {
  let result = {};
  for (const kk in obj) {
    result[kk] = {};
    for (const k in format) {
      result[kk][k] = obj[kk][format[k]];
    }
  }
  return Object.values(result);
};

export const fileToBase64 = (file: Blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export const compareKeys = (a: object | null, b: object | null): boolean => {
  if (!a || !b) return false;

  var aKeys = Object.keys(a).sort();
  var bKeys = Object.keys(b).sort();
  return JSON.stringify(aKeys) === JSON.stringify(bKeys);
};

export const getKbImage = (base64: string): number =>
  (4 * Math.ceil((base64.length - 22) / 3) * 0.5624896334383812) / 1000;
