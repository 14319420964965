import { useSelector } from 'react-redux';

import { selectGlobalSelects } from 'app/selectors';
import { Selects } from 'app/types';

import { CacheItem } from 'utils/common/session';
import { exSelects } from 'utils/types/examples';

/**
 * [HOOK] useGetSelects returns the current user's selects from the Local Storage
 *
 * @returns {Selects} the Selects object
 *
 * @author [Emanuele Moricci](https://github.com/emanuele-moricci-shippypro)
 */
const useGetSelects = (): Selects => {
  return useSelector(selectGlobalSelects) || getSelects();
};

/**
 * Function that returns the current user's selects from the Local Storage
 *
 * @returns {Selects} the Selects object
 *
 * @author [Emanuele Moricci](https://github.com/emanuele-moricci-shippypro)
 */
export const getSelects = (): Selects => {
  return JSON.parse(CacheItem('selects')) || exSelects;
};

export default useGetSelects;
