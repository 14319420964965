import { Preferences, Selects, User } from 'app/types';

export const exUser: User = {
  id: -1,
  email: '',
  first_name: '',
  last_name: '',
  role: '',
  phone: '',
  status: -1,
  img_url: '',
  access: '',
  created_at: '',
  teams: ['-1'],
  country: '',
  roles: { data: [{ team_id: -1, role_id: -1 }] },
};

export const exSelects: Selects = {
  roles: [],
  statuses: [],
  languages: [],
  skins: [],
  dimensions: [],
  currencies: [],
  weights: [],
  dateFormats: [],
  timeFormats: [],
  countries: [],
};

export const exPreferences: Preferences = {
  lang: null,
  dimension: null,
  currency: null,
  weight: null,
  dateFormat: null,
  timeFormat: null,
  skin: null,
};
