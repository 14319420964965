import { useSelector } from 'react-redux';

import { selectGlobalUser } from 'app/selectors';
import { User } from 'app/types';

import { CacheItem } from 'utils/common/session';

/**
 * [HOOK] useGetUser returns the current user's data from the Local Storage
 *
 * @returns {User} the User object
 *
 * @author [Emanuele Moricci](https://github.com/emanuele-moricci-shippypro)
 */
const useGetUser = (): User => {
  return useSelector(selectGlobalUser) || getUser();
};

/**
 * Function that returns the current user's data from the Local Storage
 *
 * @returns {User} the User object
 *
 * @author [Emanuele Moricci](https://github.com/emanuele-moricci-shippypro)
 */
export const getUser = (): User => {
  return JSON.parse(CacheItem('user'));
};

export default useGetUser;
