import { Preferences } from 'app/types';
import { getPreferences } from 'hooks/useGetPreferences';
import { getSelects } from 'hooks/useGetSelects';

import { findOrFirstOrNull } from 'utils/common';
import { updateCountriesLang } from 'utils/common/settings';

export const isAdmin = (role: string | undefined | null): boolean => {
  if (!role) return false;

  const de = require('locales/de/translation.json');
  const en = require('locales/en/translation.json');
  const es = require('locales/es/translation.json');
  const fr = require('locales/fr/translation.json');
  const it = require('locales/it/translation.json');

  return (
    role === de['teams']['roles']['admin'] ||
    role === en['teams']['roles']['admin'] ||
    role === es['teams']['roles']['admin'] ||
    role === fr['teams']['roles']['admin'] ||
    role === it['teams']['roles']['admin']
  );
};

export const isProductionEnv = (): boolean =>
  process.env.NODE_ENV === 'production' &&
  process.env.REACT_APP_ENV !== 'UNSTABLE';

export const isLocalEnv = (): boolean =>
  process.env.NODE_ENV !== 'production' &&
  process.env.REACT_APP_ENV !== 'UNSTABLE';

export const isShippyProUser = (): boolean => {
  const id = GetUserId() as number;
  return (
    [
      1,
      1769,
      6768,
      7720,
      11354,
      13076,
      8459,
      19294,
      11900,
      19607,
      18386,
      20780,
      22420,
      28598,
      25272,
      25280,
      28555,
      25971,
      32608,
      31209,
      40140,
      38517,
      46557,
      31741,
      48433,
      50568,
      49248,
      49685,
      51317,
      53612,
      54395,
      56231,
      57302,
      55614,
    ].indexOf(+id) !== -1
  );
};

const getTokenData = (param: string) => {
  let token = CacheItem('settings-token'),
    redirect = 'login.html';

  if (token) {
    token = JSON.parse(token);
    if (token[param] && token.e) {
      if (~~(Date.now() / 1000) < token.e) {
        return token[param];
      } else {
        redirect = process.env.NEW_TOKEN_PAGE || '';
      }
    }
  }
  window.location.href = `${process.env.REACT_APP_LEGACY_URL}/${redirect}`;
  return 0;
};

export const GetToken = () => {
  return getTokenData('t');
};

export const GetUserId = () => {
  return getTokenData('u');
};

export const GetAdminUserId = () => {
  return getTokenData('a');
};

export const GetUserTeamId = () => {
  return getTokenData('tid');
};

export const CacheItem = (page: string, key?: string | null, value?: any) => {
  const hasValue = typeof value !== 'undefined';

  if (key === null || typeof key == 'undefined') {
    return hasValue
      ? localStorage.setItem(page, JSON.stringify(value))
      : localStorage.getItem(page);
  }

  let cacheString: string | null = localStorage.getItem(page);

  !cacheString && (cacheString = '{}');

  let cacheObj = JSON.parse(cacheString);
  if (!hasValue) return cacheObj[key] || null;
  cacheObj[key] = value;
  localStorage.setItem(page, JSON.stringify(cacheObj));
};

export const logout = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('settings-token');
  window.location.href = `${process.env.REACT_APP_LEGACY_URL}/login.html`;
};

export const SetTabUrl = (inputData: string[], tab?: number): number => {
  const hash = window.location.hash;

  if (typeof tab !== 'undefined') {
    window.history.pushState(
      { activeTab: inputData[tab] },
      inputData[tab],
      window.location.pathname + '#' + inputData[tab],
    );
  }

  const activeTab = inputData.indexOf(hash.substring(1));
  return activeTab === -1 ? 0 : activeTab;
};

export const setPreferences = (prefs: any): Preferences => {
  let preferences = getPreferences(),
    selects = getSelects();

  preferences = {
    ...preferences,
    lang: findOrFirstOrNull(selects.languages, `${prefs.lang}`, 'id'),
    currency: findOrFirstOrNull(selects.currencies, `${prefs.currency}`),
    dimension: findOrFirstOrNull(selects.dimensions, `${prefs.dimension}`),
    weight: findOrFirstOrNull(selects.weights, `${prefs.weight}`),
    dateFormat: findOrFirstOrNull(selects.dateFormats, `${prefs.dateFormat}`),
    timeFormat: findOrFirstOrNull(selects.timeFormats, `${prefs.timeFormat}`),
    skin: findOrFirstOrNull(selects.skins, `${prefs.skin}`, 'id'),
  };

  const countries = CacheItem('selects', 'countries');

  if (countries) {
    CacheItem('selects', 'countries', updateCountriesLang(countries, 'label'));
  }

  CacheItem('preferences', null, preferences);
  return preferences;
};
