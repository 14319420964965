import { ListViewIcon } from '@shippypro/design-system/components/tables/listView/types';
import { Color } from '@shippypro/design-system/enum';
import { Plans } from 'utils/common/enums';

/* --- STATE --- */
export interface BillingState {
  addBillingContactDrawer: {
    contact: any | null;

    open: boolean;
    loading: boolean;
    error: string | null;
    success: boolean;
  };

  invoices: {
    loading: boolean;
    error: string | null;
    data: Invoice[];
  };

  cancellation: {
    loading: boolean;
    data: CancellationData | null;
    error: string | null;

    unsubscribing: boolean;
    unsubData: object | null;
    unsubSuccess: boolean;
    unsubError: string | null;

    downgrading: boolean;
    downgradeSuccess: boolean;
    downgradeError: string | null;

    cancelRequest: CancelRequest | null;
    cancelSuccess: boolean;
    cancelError: string | null;
  };
}

export interface ViewInvoice {
  uid: number;
  plan_name: string;
  description: string;
  additional_options: string[];
  is_trial: boolean;
  issuing_date: string;
  expiration_date: string;
  price: string;
  current_shipments: number;
  total_shipments: number;
  invoice_url: string | null;
  is_expired: boolean;
}

export interface Invoice {
  id: string;
  description: string;
  icon: ListViewIcon;
  date: { start: string; end: string };
  shippedOrders: string;
  price: string;
  url: string | null;
  expired: boolean;
}

export interface CancellationData {
  plan: Plans;
  trial: boolean;
  expiration: number | null;
  unsubOn?: string | null;
  downgradeOn?: string | null;
  statistics: CancellationStatistic[];
}

export interface ViewCancellation {
  uid: number;
  plan_name: Plans;
  is_trial: boolean;
  addresses_configured: number;
  connected_carriers: number;
  connected_marketplaces: number;
  expiration_date: number | null;
  residual_teammates: number;
  parcels_configured: number;
  residual_orders: number;
  connected_printers: number;
  shipping_rules_configured: number;
  unsub_on?: string | null;
  downgrade_on?: string | null;
}

export interface CancellationStatistic {
  icon: string;
  color: Color;
  title: string;
  subtitle: string;
  empty: boolean;
}

export enum CancelRequest {
  Unsub = 'Unsub',
  Downgrade = 'Downgrade',
}

export enum InvoiceType {
  RENEWAL = 'RENEWAL',
  BUY_ORDERS = 'BUY_ORDERS',
  CHANGE_TO_ANNUAL = 'CHANGE_TO_ANNUAL',
  UPG_TO_PROFESSIONAL = 'UPG_TO_PROFESSIONAL',
  DWN_TO_FASTGROWING = 'DWN_TO_FASTGROWING',
  FROM_TRIAL = 'FROM_TRIAL',
  TRIAL = 'TRIAL',
}

export enum InvoiceOptionType {
  STORESCARRIERSCONNECTION = 'STORESCARRIERSCONNECTION',
  CARRIERSPRICESSETUP = 'CARRIERSPRICESSETUP',
  PRINTERSETUP = 'PRINTERSETUP',
}

export type ContainerState = BillingState;
