import { Fragment } from 'react';
import { TFunction } from 'i18next';

import { translations } from 'locales/translations';

import { arrayColumn } from '@shippypro/design-system/utils';

import {
  ViewInvoice,
  InvoiceType,
  InvoiceOptionType,
} from 'app/containers/Billing/types';
import { ListViewIcon } from '@shippypro/design-system/components/tables/listView/types';

export const getFeedbackProgressiveFromCode = (code: string): number => {
  switch (code) {
    case 'other':
      return 1;
    case 'techIssues':
      return 2;
    case 'support':
      return 3;
    case 'expensive':
      return 4;
    case 'complicated':
      return 5;
    case 'else':
      return 6;
    case 'funcs':
      return 7;
    case 'new':
      return 8;
    case 'emails':
      return 9;
    default:
      return -1;
  }
};

export const updateCountriesLang = (
  countries: { name: string; code: string }[],
  key: string,
) => {
  const lang = localStorage.getItem('i18nextLng') || 'en',
    listCountriesTranslated = arrayColumn(
      require('localized-countries')(
        require('localized-countries/data/' + lang.substr(0, 2)),
      ).array(),
      'label',
      'code',
    );

  return countries.map((c: { [k: string]: string; code: string }) => {
    c[key] = listCountriesTranslated[c.code] ?? c[key];
    return c;
  });
};

export const getPlanInfoFromInvoice = (
  t: TFunction,
  { plan_name, description, additional_options }: ViewInvoice,
  difference_shipments: number,
): { description: string; icon: ListViewIcon } => {
  const trans = translations.billing.invoices;
  let resDesc = plan_name,
    resIcon = 'Circle',
    resTooltip = <></>;
  switch (description) {
    case InvoiceType.RENEWAL:
      resDesc = plan_name;
      resIcon = 'RefreshCw';
      resTooltip = <>{t(trans.renewal)}</>;
      break;
    case InvoiceType.BUY_ORDERS:
      resDesc = t(trans.types.BUY_ORDERS, { orders: difference_shipments });
      resIcon = 'Package';
      resTooltip = <>{t(trans.shipments)}</>;
      break;
    case InvoiceType.CHANGE_TO_ANNUAL:
      resDesc = t(trans.annual);
      resIcon = 'SkipForward';
      resTooltip = <>{t(trans.billingOptionUpdate)}</>;
      break;
    case InvoiceType.UPG_TO_PROFESSIONAL:
      resDesc = plan_name;
      resIcon = 'ChevronsUp';
      resTooltip = <>{t(trans.upgrade)}</>;
      break;
    case InvoiceType.DWN_TO_FASTGROWING:
      resDesc = plan_name;
      resIcon = 'ChevronsDown';
      resTooltip = <>{t(trans.downgrade)}</>;
      break;
    case InvoiceType.FROM_TRIAL:
      resDesc = plan_name;
      resIcon = 'ChevronsUp';
      resTooltip = <>{t(trans.types.FROM_TRIAL)}</>;
      break;
    case InvoiceType.TRIAL:
      resDesc = `${plan_name} - ${t(trans.types.TRIAL)}`;
      break;
  }

  if (additional_options && additional_options.length > 0) {
    const optionTexts = additional_options.map(o =>
      getTraslationFromPlanOptionCode(t, o),
    );

    resDesc = t(trans.additionalOption);
    resIcon = 'Sliders';
    resTooltip = (
      <>
        {optionTexts.map((opt, i) => (
          <Fragment key={i}>
            • {opt}
            <br />
          </Fragment>
        ))}
      </>
    );
  }

  return {
    description: resDesc,
    icon: {
      code: resIcon,
      tooltip: resTooltip,
    },
  };
};

export const getTraslationFromPlanOptionCode = (
  t: TFunction,
  code: string,
): string => {
  const trans = translations.billing.invoices.options;
  let res = '';
  switch (code) {
    case InvoiceOptionType.STORESCARRIERSCONNECTION:
      res = `${t(trans.STORESCARRIERSCONNECTION)}`;
      break;
    case InvoiceOptionType.CARRIERSPRICESSETUP:
      res = `${t(trans.CARRIERSPRICESSETUP)}`;
      break;
    case InvoiceOptionType.PRINTERSETUP:
      res = `${t(trans.PRINTERSETUP)}`;
      break;
  }

  return res;
};
