import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'utils/@reduxjs';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.global || initialState;

export const selectGlobalData = createSelector(
  [selectDomain],
  globalState => globalState,
);

export const selectGlobalUser = createSelector(
  [selectDomain],
  globalState => globalState.user,
);

export const selectGlobalSelects = createSelector(
  [selectDomain],
  globalState => globalState.selects,
);

export const selectGlobalPreferences = createSelector(
  [selectDomain],
  globalState => globalState.preferences,
);
