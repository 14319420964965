import { useSelector } from 'react-redux';

import { selectGlobalPreferences } from 'app/selectors';
import { Preferences } from 'app/types';

import { CacheItem } from 'utils/common/session';

/**
 * [HOOK] useGetPreferences returns the current user's preferences from the Local Storage
 *
 * @returns {Preferences} the Preferences object
 *
 * @author [Emanuele Moricci](https://github.com/emanuele-moricci-shippypro)
 */
const useGetPreferences = (): Preferences => {
  return useSelector(selectGlobalPreferences) || getPreferences();
};

/**
 * Function that returns the current user's preferences from the Local Storage
 *
 * @returns {Preferences} the Preferences object
 *
 * @author [Emanuele Moricci](https://github.com/emanuele-moricci-shippypro)
 */
export const getPreferences = (): Preferences => {
  return JSON.parse(CacheItem('preferences')) || {};
};

export default useGetPreferences;
