import { Integrations } from '@sentry/tracing';
import { createBrowserHistory } from 'history';
import * as Sentry from '@sentry/react';
import { isLocalEnv } from 'utils/common/session';

export const initSentry = () => {
  if (!isLocalEnv()) {
    const history = createBrowserHistory();

    Sentry.init({
      dsn: process.env.REACT_APP_DSN_SENTRY,
      release:
        'settings.shippypro.com@' + process.env.REACT_APP_NPM_PACKAGE_VERSION,
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        }),
      ],

      // Percentage of transactions acquired
      tracesSampleRate: 0.1,
    });
  }
};
