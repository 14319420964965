import { TFunction } from 'i18next';

import { translations } from 'locales/translations';

import { ArrayProps } from 'utils/types';

import {
  Country,
  Role,
  RoleSelect,
  CountrySelect,
  listPreferences,
} from 'app/types';
import { convertObj, toLowerFirst } from 'utils/common';

// API
export const getLngRoles = (t: TFunction, roles: Role[]): RoleSelect[] => {
  return roles.map((r: Role) => ({
    value: r.id.toString(),
    label: t(translations.teams.roles[toLowerFirst(r.name.replace(' ', ''))]),
    icon: r.icon,
  }));
};

export const getLngCountries = (
  t: TFunction,
  countries: Country[],
): CountrySelect[] => {
  return countries.map((c: Country) => ({
    value: c.id.toString(),
    label: c.name,
    code: c.code,
    alpha3: c.alpha3,
    zip_required: c.zip_required,
    province_required: c.province_required,
  }));
};

// LOCAL
export const getStatuses = (t: TFunction): ArrayProps[] => [
  {
    label: t(translations.teams.status.active),
    value: 'Active',
  },
  {
    label: t(translations.teams.status.inactive),
    value: 'Inactive',
  },
  {
    label: t(translations.teams.status.pendingInvitation),
    value: 'Pending invitation',
  },
];

export const getPreferencesFormatted = (
  t: TFunction,
  listPreferences: any,
): listPreferences => {
  const languages = convertObj(
      listPreferences.data.filter(
        (r: { slug: string }) => r.slug === 'ui_language',
      ),
      {
        id: 'option_sub_id',
        value: 'value_main',
        country: 'value_auxiliary',
      },
    ).map((r: any) => {
      r.id = r.id.toString();
      r.label = t(translations.account.preferences.languages[r.value]);
      return r;
    }),
    dimensions = convertObj(
      listPreferences.data.filter(
        (r: { slug: string }) => r.slug === 'package_size',
      ),
      {
        value: 'option_sub_id',
        label: 'value_main',
      },
    ).map((r: any) => {
      r.value = r.value.toString();
      return r;
    }),
    currencies = convertObj(
      listPreferences.data.filter(
        (r: { slug: string }) => r.slug === 'currency',
      ),
      {
        value: 'option_sub_id',
        code: 'value_main',
        symbol: 'value_auxiliary',
      },
    ).map((r: any) => {
      r.value = r.value.toString();
      r.name = t(translations.account.preferences.currencies[r.code]);
      return r;
    }),
    weights = convertObj(
      listPreferences.data.filter((r: { slug: string }) => r.slug === 'weight'),
      {
        value: 'option_sub_id',
        label: 'value_main',
      },
    ).map((r: any) => {
      r.value = r.value.toString();
      return r;
    }),
    skins = convertObj(
      listPreferences.data.filter(
        (r: { slug: string }) => r.slug === 'interface_colours',
      ),
      {
        id: 'id',
        value: 'value_main',
      },
    ).map((r: any) => {
      r.label = t(
        translations.account.preferences.skins[
          {
            light: 'lightMode',
            'semi-dark': 'semiDarkMode',
            dark: 'darkMode',
          }[r.value]
        ],
      );

      r.id = r.id.toString();
      return r;
    }),
    dateFormats = convertObj(
      listPreferences.data.filter(
        (r: { slug: string }) => r.slug === 'date_format',
      ),
      {
        value: 'id',
        label: 'value_main',
        valueAux: 'value_auxiliary',
      },
    ).map((r: any) => {
      r.value = r.value.toString();
      return r;
    }),
    timeFormats = convertObj(
      listPreferences.data.filter(
        (r: { slug: string }) => r.slug === 'time_format',
      ),
      {
        value: 'id',
        label: 'value_main',
        valueAux: 'value_auxiliary',
      },
    ).map((r: any) => {
      r.label = t(translations.account.preferences.timeFormats[r.label]);
      r.value = r.value.toString();
      return r;
    });

  return {
    languages,
    skins,
    dimensions,
    currencies,
    weights,
    dateFormats,
    timeFormats,
  };
};
