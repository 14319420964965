import * as Icon from '@shippypro/design-system/icons';
import { translations } from 'locales/translations';
import { isProductionEnv } from 'utils/common/session';

const navigationConfig = (t, flags) => [
  {
    id: 'profile',
    groupTitle: t(translations.common.profile),
    type: 'groupHeader',
  },
  {
    id: 'teams',
    title: t(translations.teams.title),
    type: 'item',
    icon: <Icon.Users size={20} />,
    navLink: '/teams',
    filterBase: '/teams',
    hide: !flags.reactAppTeams,
  },
  {
    id: 'account',
    title: t(translations.account.title),
    type: 'item',
    icon: <Icon.User size={20} />,
    navLink: '/account',
    filterBase: '/account',
    hide: !flags.reactAppAccount,
  },
  {
    id: 'company',
    title: t(translations.company.title),
    type: 'item',
    icon: <Icon.Info size={20} />,
    navLink: '/company',
    filterBase: '/company',
    hide: !flags.reactAppCompany,
  },
  ...(!isProductionEnv()
    ? [
        {
          id: 'billing',
          title: t(translations.billing.title),
          type: 'item',
          icon: <Icon.CreditCard size={20} />,
          navLink: '/billing',
          filterBase: '/billing',
        },
        {
          id: 'preferences',
          groupTitle: t(translations.common.preferences),
          type: 'groupHeader',
        },
        {
          id: 'book',
          title: t(translations.book.title),
          type: 'item',
          icon: <Icon.MapPin size={20} />,
          navLink: '/book',
          filterBase: '/book',
        },
        {
          id: 'utilities',
          title: t(translations.utilities.title),
          type: 'item',
          icon: <Icon.Paperclip size={20} />,
          navLink: '/utilities',
          filterBase: '/utilities',
        },
      ]
    : []),
  {
    id: 'app',
    groupTitle: t(translations.common.app),
    type: 'groupHeader',
  },
  {
    id: 'shippypro',
    title: t(translations.nav.sidebar.launch),
    type: 'external-link',
    newTab: true,
    icon: <Icon.LogoGray size={20} />,
    navLink: `${process.env.REACT_APP_LEGACY_URL}/panel/index.html`,
  },
];

export default navigationConfig;
