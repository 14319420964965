import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// ---- sidebar
import { Sidebar as ShippyProSidebar } from '@shippypro/design-system';
import NavigationConfig from './navigationConfig';
// ---- end sidebar

import { ReactComponent as ShippyProLogo } from 'assets/img/logo/logo-full.svg';
import { useFlags } from 'launchdarkly-react-client-sdk';

const Sidebar = ({ ...rest }) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    rest.handleActivePath(pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let handleCollapsedMenuPaths = (item: any) => {},
    sidebarHover = (item: any) => {},
    toggle = (item: any) => {},
    toggleSidebarMenu = (item: any) => {};

  const sidebarArgs = {
    activeItem: pathname,
    handleActiveItem: rest.handleActivePath,
    collapseSidebar: rest.handleCollapse,
    collapsedContent: rest.collapsedContent,
    handleSidebarVisibility: rest.handleSidebarVisibility,
    visibilityState: rest.sidebarHidden,

    activeTheme: 'primary',
    collapsed: false,
    currentLang: 'en',
    deviceWidth: 1920,
    permission: undefined,
    sidebarState: false,
    navigationConfig: NavigationConfig(t, { ...useFlags() }),
    collapsedMenuPaths: handleCollapsedMenuPaths,
    sidebarHover: sidebarHover,
    toggle: toggle,
    toggleSidebarMenu: toggleSidebarMenu,
    headerHomeLink: (
      <span className="navbar-brand cinitial">
        <ShippyProLogo className="trans100" />
      </span>
    ),
  };

  return <ShippyProSidebar {...sidebarArgs} />;
};

export default Sidebar;
