import CardWrapper from 'app/components/wrappers/CardWrapper';
import { Spinner } from 'reactstrap';

const Preload = () => {
  return (
    <>
      <CardWrapper className="full-size">
        <Spinner color="grey" size="xl" />
      </CardWrapper>
    </>
  );
};

export default Preload;
