import { useState, useEffect, SetStateAction } from 'react';
import ScrollToTop from 'react-scroll-up';
import classnames from 'classnames';
import { useLDClient } from 'launchdarkly-react-client-sdk';

import { logout } from 'utils/common/session';
import { toLDUser } from 'utils/@launchdarkly';

import Sidebar from './containers/_layout/Navigation/Sidebar';
import Navbar from './containers/_layout/Navigation/Navbar';

import { Switch, Route } from 'react-router-dom';

import Routes from './routes';
import { User } from './types';
import { memo } from 'react';

import { Button } from '@shippypro/design-system';
import { ArrowUp } from '@shippypro/design-system/icons';

interface Props {
  user: User;
}

const loginUrl = `${process.env.REACT_APP_LEGACY_URL}/login.html`;

const Authenticated = memo(({ user, ...rest }: Props) => {
  //re-initialize LaunchDarkly if the user wasn't present on load
  const client = useLDClient();
  if (user) {
    client?.identify(toLDUser(user));
  }

  const savedTheme = localStorage.getItem('theme') || 'light';

  let [collapsedContent, setCollapsedContent] = useState(false),
    [sidebarHidden, setSidebarHidden] = useState(false),
    [activePath, setActivePath] = useState('/'),
    [theme, setTheme] = useState(savedTheme),
    [lng, setLng] = useState(localStorage.getItem('i18nextLng')),
    scrollTopStyle = {
      zIndex: 9,
      position: 'fixed',
      bottom: 50,
      right: 30,
      cursor: 'pointer',
      transitionDuration: '0.2s',
      transitionTimingFunction: 'linear',
      transitionDelay: '0s',
    };

  useEffect(() => {
    var classList = document.body.classList;
    while (classList.length > 0) {
      document.body.classList.remove(classList.item(0) || '');
    }
    document.body.classList.add(`${theme}-layout`);
    localStorage.setItem('theme', theme);
  }, [theme, lng]);

  return (
    <div
      className={classnames(`wrapper vertical-layout theme-primary`, {
        'menu-collapsed': collapsedContent === true,
      })}
    >
      <Sidebar
        collapsedContent={collapsedContent}
        activePath={activePath}
        sidebarHidden={sidebarHidden}
        handleActivePath={(url: SetStateAction<string>) => setActivePath(url)}
        handleCollapse={() => setCollapsedContent(!collapsedContent)}
        handleSidebarVisibility={() => setSidebarHidden(!sidebarHidden)}
      />
      <div className="app-content content">
        <div className="header-navbar-shadow"></div>
        <Navbar
          sidebarHidden={sidebarHidden}
          handleSidebarVisibility={() => setSidebarHidden(!sidebarHidden)}
          setTheme={(theme: SetStateAction<string>) => setTheme(theme)}
          name={user ? `${user.first_name} ${user.last_name}` : ''}
          img={user ? user.img_url : null}
          lng={lng}
          handleLngChange={(lng: string) =>
            setTimeout(() => {
              setLng(lng);
            }, 200)
          }
        />

        <Routes lng={lng} theme={theme} setTheme={t => setTheme(t)} />
      </div>

      <div
        className="sidenav-overlay"
        onClick={() => setSidebarHidden(!sidebarHidden)}
      />

      <ScrollToTop showUnder={160} style={scrollTopStyle}>
        <Button
          color="primary"
          className="btn-icon scroll-top"
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          style={{ marginBottom: -15, marginRight: -22 }}
        >
          <ArrowUp size={15} />
        </Button>
      </ScrollToTop>
    </div>
  );
});

const Proxy = () => {
  let search: string = window.location.search,
    searchToObject = (): object => {
      let pairs: string[] = search.substring(1).split('&'),
        obj: object = {},
        pair: string[],
        i: string | number;

      for (i in pairs) {
        if (pairs[i] === '') continue;

        pair = pairs[i].split('=');
        obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
      }
      return obj;
    };

  if (search && search !== '?') {
    const searchObj: object = searchToObject();

    window.history.replaceState &&
      window.history.replaceState(null, '', window.location.pathname);
    localStorage.setItem('settings-token', JSON.stringify(searchObj));

    if (searchObj['target']) {
      window.location.href = searchObj['target'];
    } else {
      localStorage.removeItem('user');
      window.location.href = '/';
    }
  } else {
    window.location.href = loginUrl;
  }
  return <></>;
};

const Logout = () => {
  logout();
  return <></>;
};

const OfflineUrls = {
  proxyPath: '/proxy',
  logout: '/logout',
};

const IsOfflineUrl = (url: string) => {
  return Object.values(OfflineUrls).indexOf(url) !== -1;
};

const OfflineRouter = () => {
  return (
    <Switch>
      <Route path={OfflineUrls.proxyPath} component={Proxy} />
      <Route path={OfflineUrls.logout} component={Logout} />
    </Switch>
  );
};

export default Authenticated;
export { Proxy, Logout, OfflineUrls, IsOfflineUrl, OfflineRouter };
