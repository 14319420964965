import CardWrapper from 'app/components/wrappers/CardWrapper';
import { AlertTriangle } from '@shippypro/design-system/icons';

import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';

const Error = ({ error, ...rest }) => {
  const { t } = useTranslation();

  return (
    <>
      <CardWrapper className="full-size">
        <AlertTriangle size="50" className="text-danger mb-20" />
        <br />
        <h1 className="fw500 text-purpleish">
          {t(translations.common.errors[error])}
        </h1>
      </CardWrapper>
    </>
  );
};

export default Error;
