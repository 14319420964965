import { lazyLoad } from 'utils/loadable';

/**
 * [HOOK] useGetLazyLoad takes the folder name in the 'app/containers' folder and returns a Lazy-Loaded index for that Container.
 *
 * @param {string} path The name of your folder
 *
 * @returns {(props: any) => JSX.Element} the lazy-loaded JSX element
 *
 * @author [Emanuele Moricci](https://github.com/emanuele-moricci-shippypro)
 */
const useGetLazyLoad = (path: string): ((props: any) => JSX.Element) => {
  return lazyLoad(
    () => import(`app/containers/${path}/index`),
    module => module[path],
  );
};

export default useGetLazyLoad;
