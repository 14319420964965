import { User } from 'app/types';

export const toLDUser = (user: User) => {
  return user
    ? {
        key: user.id.toString(),
        name: `${user.first_name} ${user.last_name}`,
        email: user.email,
        country: user.country === null ? undefined : user.country,
        custom: {
          created_at: user.created_at ? new Date(user.created_at).getTime() : 0,
        },
      }
    : {};
};
