import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ContainerState, User, UserData } from './types';

// The initial state of the Global container
export const initialState: ContainerState = {
  authenticated: true,
  error: null,
  user: null,
  selects: null,
  preferences: null,

  isMobile: false,
};

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    // GET DATA
    getUserInfo(state) {
      state.authenticated = false;
    },
    updateUser(state, action: PayloadAction<User>) {
      state.user = action.payload;
    },
    userInfoLoaded(state, action: PayloadAction<UserData>) {
      state.authenticated = true;
      state.user = action.payload.user;
      state.selects = action.payload.selects;
      state.preferences = action.payload.preferences;
    },
    userInfoError(state, action: PayloadAction<string>) {
      state.authenticated = true;
      state.error = action.payload;
    },
    savePreferences(state, action: PayloadAction<UserData>) {
      state.preferences = action.payload.preferences;
    },

    //UPDATE VIEWPORT
    updateViewport(state, action: PayloadAction<boolean>) {
      state.isMobile = action.payload;
    },

    //UPDATE LANGUAGE
    updateLanguage() {},
  },
});

export const { actions: globalActions, reducer, name: sliceKey } = globalSlice;
