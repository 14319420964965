/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import * as serviceWorker from 'serviceWorker';

// Use consistent styling
import 'sanitize.css/sanitize.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@shippypro/design-system/assets/scss/app.scss';
import 'assets/styles/custom.scss';

// Import root app
import { App } from 'app';

import { HelmetProvider } from 'react-helmet-async';

import { configureAppStore } from 'store/configureStore';

import { getUser } from 'hooks/useGetUser';

// Initialize languages
import './locales/i18n';
import { initSentry } from 'utils/@sentry';
import { toLDUser } from 'utils/@launchdarkly';

initSentry();

const store = configureAppStore();
const MOUNT_NODE = document.getElementById('root') as HTMLElement;

(async () => {
  const user = getUser(),
    LDProvider = await asyncWithLDProvider({
      clientSideID:
        process.env.REACT_APP_LAUNCHDARKLY_CLIENTID || 'CLIENTID_NOT_FOUND',
      user: user ? toLDUser(user) : undefined,
    });
  render(
    <LDProvider>
      <Provider store={store}>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </Provider>
    </LDProvider>,
    MOUNT_NODE,
  );
})();

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(['./locales/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
