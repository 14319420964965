import axios, { AxiosResponse } from 'axios';
import { ResponseError, handleErrors } from './exceptions';
import { GetToken } from 'utils/common/session';

/**
 * Prepares the Headers of the request with the Auth Bearer Token
 *
 * @return {object}           The request header
 */
const getAuthConfig = () => {
  const token = GetToken();
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      Accept: '*/*',
    },
  };
};

/**
 * Requests a URL in GET, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export async function getRequest(
  url: string,
  options?: Record<string, unknown>,
): Promise<{} | { err: ResponseError }> {
  return await axios
    .get(url, { ...options, ...getAuthConfig() })
    .then((response: AxiosResponse) => {
      handleErrors({ response });
      return response.data;
    });
}

/**
 * Requests a URL in POST, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [data]    The POST body for the request
 *
 * @return {object}           The response data
 */
export async function postRequest(
  url: string,
  data?: Record<string, unknown>,
  options?: Record<string, unknown>,
): Promise<{} | { err: ResponseError }> {
  return await axios
    .post(url, data, { ...options, ...getAuthConfig() })
    .then((response: AxiosResponse) => {
      handleErrors({ response });
      return response.data;
    });
}

/**
 * Requests a URL in POST with a data array, returning a promise
 *
 * @param  {string} url         The URL we want to request
 * @param  {object[]} [data]    The POST array for the request
 *
 * @return {object}           The response data
 */
export async function postArrRequest(
  url: string,
  data?: object[],
  options?: Record<string, unknown>,
): Promise<{} | { err: ResponseError }> {
  return await axios
    .post(url, data, { ...options, ...getAuthConfig() })
    .then((response: AxiosResponse) => {
      handleErrors({ response });
      return response.data;
    });
}

/**
 * Requests a URL in PATCH, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [data]    The PATCH body for the request
 *
 * @return {object}           The response data
 */
export async function patchRequest(
  url: string,
  data?: Record<string, unknown>,
  options?: Record<string, unknown>,
): Promise<{} | { err: ResponseError }> {
  return await axios
    .patch(url, data, { ...options, ...getAuthConfig() })
    .then((response: AxiosResponse) => {
      handleErrors({ response });
      return response.data;
    });
}

/**
 * Requests a URL in DELETE, returning a promise
 *
 * @param  {string} url          The URL we want to request
 * @param  {object} [options]    The DELETE options for the request
 *
 * @return {object}           The response data
 */
export async function deleteRequest(
  url: string,
  options?: Record<string, unknown>,
): Promise<{} | { err: ResponseError }> {
  return await axios
    .delete(url, { ...options, ...getAuthConfig() })
    .then((response: AxiosResponse) => {
      handleErrors({ response });
      return response.data;
    });
}
