import { memo, ReactNode } from 'react';
import { Card, CardBody } from '@shippypro/design-system';

interface Props {
  className?: string;
  children: ReactNode;
  centered?: boolean;
}

const CardWrapper = memo(({ centered = true, ...rest }: Props) => {
  return (
    <Card className={`dynamic-height ${rest.className}`}>
      <CardBody className={centered ? 'tc m-auto' : ''}>
        <div>{rest.children}</div>
      </CardBody>
    </Card>
  );
});

export default CardWrapper;
