import { AxiosResponse } from 'axios';
import { LegacyException, Exception } from 'utils/types/exceptions';

export const requestBaseURL = `${process.env.REACT_APP_SETTINGS_API}/v1/`;

/**
 * The Error Response class
 * @class ResponseError
 *
 * @param  {object} response  The error object returned from a Catch statement
 */
export class ResponseError extends Error {
  public response: Response;

  constructor(response: Response) {
    super(response.statusText);
    this.response = response;
  }
}

/**
 * Handles an exception coming from the Legacy Gateway
 *
 * @param  {string|null} [message]     The error message returned from a Catch statement
 *
 * @return {string}                    The translatable error code
 */
const handleLegacyException = (message?: string | null): string => {
  let error = '';

  if (!message) {
    error = Exception.generic;
  } else {
    switch (message) {
      case LegacyException.userExists:
        error = LegacyException.userExists;
        break;
      case LegacyException.emailError:
        error = LegacyException.emailError;
        break;
      default:
        error = Exception.generic;
    }
  }

  return error;
};

/**
 * Returns a translatable error code from a failed Axios API call
 *
 * @param  {object} error     The error object returned from a Catch statement
 * @param  {boolean} [legacy] An optional field to signal that the legacy system was called
 *
 * @return {string}           The translatable error code
 */
export const handleErrors = (
  error: { response?: AxiosResponse; message?: string },
  legacy: boolean = false,
): string => {
  if (error.response && error.response.status === 200) return '';

  console.log('API error: ', error);
  if (!error.response && !legacy) {
    const err = Exception.generic;

    return err;
  }

  if (legacy) return handleLegacyException(error.message);

  if (error.response?.data?.error_code === 'INVALID_AUTHORIZATION_CODE') {
    window.location.href = `${process.env.REACT_APP_LEGACY_URL}/${process.env.REACT_APP_NEW_TOKEN_PAGE}`;
  }

  const formattedError = [500, 501, 401, 403, 404, 429].includes(
    error.response?.status || -1,
  )
    ? error.response?.status.toString() || Exception.generic
    : Exception.generic;

  return formattedError;
};
