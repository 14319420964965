import { useTranslation } from 'react-i18next';
import ReactCountryFlag from 'react-country-flag';
import styled from 'styled-components';
//import { FormGroup } from 'reactstrap';

import {
  useInjectReducer,
  useInjectSaga,
} from 'utils/@reduxjs/redux-injectors';
import { globalActions, reducer, sliceKey } from 'app/slice';
import { globalSaga } from 'app/saga';
import { useDispatch } from 'react-redux';
import { isProductionEnv } from 'utils/common/session';

import { translations } from 'locales/translations';

import {
  // Search,
  // User,
  // Power,
  Menu,
} from '@shippypro/design-system/icons';
import {
  Navbar as ShippyProNavbar,
  NavItem,
  NavLink,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Avatar,
  // Input,
} from '@shippypro/design-system';
import { Color, Status, StyleType } from '@shippypro/design-system/enum';
import {
  getAvatarData,
  isNullOrEmptyString,
} from '@shippypro/design-system/utils';
import { arrayColumn } from '@shippypro/design-system/utility/functions';
import useGetSelects from 'hooks/useGetSelects';

const Navbar = ({ ...rest }) => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: globalSaga });

  const d = useDispatch(),
    { t, i18n } = useTranslation(),
    { languages } = useGetSelects();

  const lngArray = arrayColumn(languages, 'label', 'value'),
    countries = arrayColumn(languages, 'country', 'value');

  const changeLanguage = (code: string) => {
    localStorage.removeItem('selects');
    d(globalActions.updateLanguage());

    i18n.changeLanguage(code).then(() => {
      rest.handleLngChange(code);
    });
  };

  const { content, color } = getAvatarData(rest.name || 'Loading ...'),
    countryCode = i18n.language.includes('en')
      ? 'us'
      : i18n.language[2]
      ? i18n.language.substr(0, 2)
      : i18n.language,
    lang = countryCode === 'us' ? 'en' : countryCode;

  return (
    <ShippyProNavbar
      color={Color.none}
      className="header-navbar navbar-expand-lg navbar navbar-with-menu navbar-light navbar-shadow floating-nav mb-2"
    >
      <div className="navbar-wrapper">
        <div className="navbar-container content">
          <div className="navbar-collapse" id="navbar-mobile">
            <div className="xmr-10 xw-auto">
              <div className="mr-auto float-left bookmark-wrapper d-flex align-items-center">
                <ul className="navbar-nav d-xl-none">
                  <NavItem className="mobile-menu mr-auto flex">
                    <NavLink
                      className="nav-menu-main menu-toggle is-active"
                      onClick={rest.handleSidebarVisibility}
                    >
                      <Menu className="ficon" />
                    </NavLink>
                  </NavItem>
                </ul>
              </div>
            </div>
            {/* [-----------------SEARCH BAR COMMENTED FOR NOW-----------------] */}
            {/* <div className="m-auto xw100">
              <FormGroup className="position-relative has-icon-left form-group-search">
                <Input
                  type="text"
                  placeholder={t(translations.nav.navbar.globalSearch)}
                  className="search-input"
                />
                <div className="form-control-position">
                  <Search className="nav-search-icon" />
                </div>
              </FormGroup>
            </div> */}
            <ul className="nav navbar-nav navbar-nav-user right xw-100">
              {isProductionEnv() ? (
                <UncontrolledButtonDropdown
                  tag="li"
                  className="dropdown-language nav-item dropdown"
                >
                  <DropdownToggle
                    tag="a"
                    styleType={StyleType.flat}
                    className="nav-link"
                  >
                    <ReactCountryFlag countryCode={countryCode} svg />
                    <span className="d-sm-inline-block d-none text-capitalize align-middle ml-50">
                      {lngArray[lang]}
                    </span>
                  </DropdownToggle>
                  <DropdownMenu right>
                    {Object.keys(countries).map(lng => (
                      <DropdownItem
                        tag="a"
                        onClick={e => changeLanguage(lng)}
                        key={lng}
                      >
                        <ReactCountryFlag countryCode={countries[lng]} svg />
                        <span className="ml-1">{lngArray[lng]}</span>
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              ) : null}
              <UncontrolledButtonDropdown
                tag="li"
                className="dropdown-user nav-item dropdown"
              >
                <DropdownToggle
                  tag="a"
                  styleType={StyleType.filled}
                  data-toggle="dropdown"
                  className="nav-link dropdown-user-link cinitial"
                >
                  <div className="user-nav d-sm-flex d-none">
                    <span className="user-name text-bold-600">{rest.name}</span>
                    <span className="user-status">
                      {t(translations.nav.navbar.status)}
                    </span>
                  </div>
                  <StyledAvatar
                    content={content}
                    color={color}
                    status={Status.online}
                    size="md"
                    className={`${!rest.img ? 'o5' : ''} cinitial`}
                    img={isNullOrEmptyString(rest.img) ? undefined : rest.img}
                  />
                </DropdownToggle>
                {/* <DropdownMenu right>
                  {/* <DropdownItem tag="a" href="#">
                    <User size={14} className="mr-50" />
                    <span className="align-bottom">
                      {t(translations.common.editProfile)}
                    </span>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem tag="a" href="/logout">
                    <Power size={14} className="mr-50" />
                    <span>{t(translations.common.logout)}</span>
                  </DropdownItem>
                </DropdownMenu> */}
              </UncontrolledButtonDropdown>
            </ul>
          </div>
        </div>
      </div>
    </ShippyProNavbar>
  );
};

const StyledAvatar = styled(Avatar)`
  .avatar-content {
    width: 40px !important;
    height: 40px !important;
    font-size: 1rem !important;
  }

  img {
    width: 40px !important;
    height: 40px !important;
  }

  [class*='avatar-status-'] {
    width: 13px !important;
    height: 13px !important;
  }
`;

export default Navbar;
