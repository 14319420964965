/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { hotjar } from 'react-hotjar';

import { useSelector } from 'react-redux';
import { selectGlobalData } from './selectors';
import { GlobalStyle } from 'styles/global-styles';

import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';

import Error from './containers/_layout/Errors/Errors';
import Preload from './containers/_layout/Preload/Preload';
import { GetToken, isProductionEnv } from 'utils/common/session';
import useGetUser from 'hooks/useGetUser';

import Authenticated, { OfflineRouter, IsOfflineUrl } from './authenticated';

import {
  useInjectReducer,
  useInjectSaga,
} from 'utils/@reduxjs/redux-injectors';
import { reducer, sliceKey } from './slice';
import { globalSaga } from './saga';

export function App() {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: globalSaga });

  const { i18n, t } = useTranslation(),
    token = GetToken(),
    user = useGetUser(),
    title = `${t(translations.common.settings)} | ShippyPro`;

  let { authenticated, error } = useSelector(selectGlobalData);

  !token && (error = 'tokenNotValid');

  // hotjar
  useEffect(() => {
    if (isProductionEnv()) hotjar.initialize(2369550, 6);
  }, []);

  return (
    <BrowserRouter>
      <Helmet
        titleTemplate={`%s - ${title}`}
        defaultTitle={title}
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content={title} />
      </Helmet>

      {IsOfflineUrl(window.location.pathname) ? (
        <OfflineRouter />
      ) : error ? (
        <Error error={error} />
      ) : !authenticated ? (
        <>
          <Preload />
        </>
      ) : (
        <Authenticated user={user} />
      )}

      <GlobalStyle />
    </BrowserRouter>
  );
}
