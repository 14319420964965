import { takeLatest, put, all, call } from 'redux-saga/effects';
import { globalActions } from './slice';
import { User, Selects, Preferences } from './types';
import {
  CacheItem,
  GetUserId,
  GetUserTeamId,
  setPreferences,
} from 'utils/common/session';
import { updateCountriesLang } from 'utils/common/settings';
import { requestBaseURL, getRequest, handleErrors } from 'utils/@axios';
import { i18n } from 'locales/i18n';
import { translations } from 'locales/translations';

import {
  getLngRoles,
  getStatuses,
  getLngCountries,
  getPreferencesFormatted,
} from 'utils/common/arrays';
import { getPreferences } from 'hooks/useGetPreferences';
import { getUser } from 'hooks/useGetUser';

export function* globalSaga() {
  yield all([
    takeLatest(globalActions.getUserInfo, getUserInfo),
    takeLatest(globalActions.updateLanguage, updateLanguage),
    takeLatest(globalActions.updateViewport, () => {}),
  ]);
}

export function* getUserInfo() {
  try {
    const userId = GetUserId(),
      teamId = GetUserTeamId(),
      t = yield i18n;

    const [selects, userPost, userRoles] = yield all([
      getGlobalData(),
      call(getRequest, `${requestBaseURL}user/${userId}`),
      call(
        getRequest,
        `${requestBaseURL}team_members_roles?team_id=${teamId}&user_id=${userId}`,
      ),
    ]);

    const user: User = {
      ...userPost,
      roles: userRoles,
      role:
        userRoles.data.length !== 0
          ? selects.roles.find(
              (role: { value: string }) =>
                role.value === userRoles.data[0].role_id.toString(),
            )?.label
          : t(translations.teams.roles.admin),
    };

    const cachePreferences = JSON.parse(CacheItem('preferences')),
      prefs = {
        lang: cachePreferences?.lang.id || '1',
        skin: cachePreferences?.skin.id || '21',
        dimension: cachePreferences?.dimension.value || '1',
        currency: cachePreferences?.currency.value || '1',
        weight: cachePreferences?.weight.value || '1',
        dateFormat: cachePreferences?.dateFormat.value || '11',
        timeFormat: cachePreferences?.timeFormat.value || '9',
      };

    if (!user) {
      // cambiare con l'errore vero
      yield put(globalActions.userInfoError('tokenNotValid'));
      return 0;
    }

    CacheItem('user', null, user);
    CacheItem('selects', null, selects);
    const preferences: Preferences = setPreferences(prefs);

    yield put(globalActions.userInfoLoaded({ user, selects, preferences }));
  } catch (err) {
    yield put(globalActions.userInfoError(handleErrors(err)));
  }
}

export function* updateLanguage() {
  try {
    const selects: Selects = yield getGlobalData(),
      t = yield i18n,
      user = getUser(),
      preferences = getPreferences();

    const userRoles = yield call(
      getRequest,
      `${requestBaseURL}team_members_roles?team_id=${
        user.teams[0] || -1
      }&user_id=${user.id}`,
    );

    user.role =
      userRoles.data.length !== 0
        ? selects.roles.find(
            role => role.value === userRoles.data[0].role_id.toString(),
          )?.label
        : t(translations.teams.roles.admin);

    CacheItem('selects', null, selects);
    CacheItem('user', null, user);

    yield put(globalActions.userInfoLoaded({ user, selects, preferences }));
  } catch (err) {
    yield put(globalActions.userInfoError(handleErrors(err)));
  }
}

function* getGlobalData() {
  const t = yield i18n;

  try {
    let [listRoles, listCountries, listPreferences] = yield all([
      call(getRequest, `${requestBaseURL}team_roles`),
      call(getRequest, `${requestBaseURL}countries?limit=300`),
      call(getRequest, `${requestBaseURL}preference_options`),
    ]);

    listCountries.data = updateCountriesLang(listCountries.data, 'name');

    const [roles, statuses, countries] = yield all([
      getLngRoles(t, listRoles.data),
      getStatuses(t),
      getLngCountries(t, listCountries.data),
    ]);

    return {
      roles,
      statuses,
      ...getPreferencesFormatted(t, listPreferences),
      countries,
    };
  } catch (err) {
    return '500';
  }
}
