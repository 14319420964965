import styled from 'styled-components/macro';
import { Switch, Route } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { isProductionEnv, isShippyProUser } from 'utils/common/session';

import useGetLazyLoad from 'hooks/useGetLazyLoad';

const Routes = ({ ...rest }) => {
  /**
   * LAZY-LOADED ROUTES.
   *
   * Routes loaded with the lazyLoad function with a custom hook
   */
  // --- [Common Routes]
  const HomePage = useGetLazyLoad('HomePage'),
    NotFoundPage = useGetLazyLoad('NotFoundPage');
  // --- [Organizations & Users]
  const Teams = useGetLazyLoad('Teams'),
    Account = useGetLazyLoad('Account'),
    Company = useGetLazyLoad('Company'),
    Billing = useGetLazyLoad('Billing');
  // --- [General Preferences]
  const Book = useGetLazyLoad('Book'),
    Utilities = useGetLazyLoad('Utilities');

  const { reactAppTeams, reactAppCompany, reactAppAccount } = useFlags(),
    shippyProUserOrNotProd = isShippyProUser() || !isProductionEnv();

  return (
    <ContentWrapperStyle className="content-wrapper pt-0">
      <Switch>
        <Route exact path="/" component={HomePage} />

        {reactAppTeams && (
          <Route
            path="/teams"
            render={props => <Teams {...props} lng={rest.lng} />}
          />
        )}
        {reactAppCompany && <Route exact path="/company" component={Company} />}

        {reactAppAccount && (
          <Route
            path="/account"
            render={props => (
              <Account
                {...props}
                theme={rest.theme}
                setTheme={t => rest.setTheme(t)}
              />
            )}
          />
        )}

        {shippyProUserOrNotProd && (
          <Route exact path="/billing" component={Billing} />
        )}
        {shippyProUserOrNotProd && (
          <Route exact path="/book" component={Book} />
        )}
        {shippyProUserOrNotProd && (
          <Route exact path="/utilities" component={Utilities} />
        )}
        <Route component={NotFoundPage} />
      </Switch>
    </ContentWrapperStyle>
  );
};

export default Routes;

const ContentWrapperStyle = styled.div`
  flex-flow: wrap;
`;
